
import { defineComponent, onMounted, ref } from "vue";
import AvaliacaoColumn from "@/util/table-columns/AvaliacaoColumn";
import AvaliacaoForm from "@/components/admin/avaliacoes/AvaliacaoForm.vue";
import useList from "@/modules/useList";
import store from "@/store";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import TipoAvaliacao from "@/interfaces/TipoAvaliacao";
import Avaliacao from "@/interfaces/Avaliacao";
import Disciplina from "@/interfaces/Disciplina";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { AvaliacaoForm },
  setup() {
    const $q = useQuasar();
    const route = useRoute();
    const filtro = ref<string>("");
    const filtroTipo = ref<string>("");
    const filtroDisciplina = ref<string>("");
    const tipoAvaliacoes = ref<TipoAvaliacao[]>([]);
    const disciplinas = ref<Disciplina[]>([]);
    const avaliacoes = ref<Avaliacao[]>([]);
    const idDisciplina: any = route.params.idDisciplina;
    const tipoAvaliacaoId = route.params.idTipoAvaliacao;
    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
    } = useList(
      store.getters.divisaoId
        ? "divisoes/avaliacoes/" + store.getters.divisaoId + "/" + idDisciplina + "/" + tipoAvaliacaoId
        : "avaliacoes/by-disciplina/" + idDisciplina + "/" + tipoAvaliacaoId,
      AvaliacaoColumn
    );

    return {
      rows,
      loading,
      filter,
      columns,
      list,
      modalForm,
      openModalForm,
      formatData,
      filtro,
      filtroTipo,
      filtroDisciplina,
      disciplinas,
      tipoAvaliacoes,
      avaliacoes,
      store
    };
  },
});
